import React from 'react'
import { default as styled } from 'styled-components'
import SEO from '../components/seo'
import Layout, { Header, MainWithMenu } from 'components/layout'
import { HeaderLink } from '../components/semanticdata'

const AnimStone = styled.svg`
  display: inline;
  position: absolute;
  bottom: 125%;

  transform-origin: center;
  transform-box: border-box;
  animation-name: falling, spinning;
  animation-duration: 12s, 4s;
  animation-timing-function: ease-in, linear;
  animation-iteration-count: infinite;

  @keyframes falling {
    from {
      bottom: 125%;
    }
    to {
      bottom: -25%;
    }
  }
  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="This is not the page you are looking for." />
    <MainWithMenu className="relative grid overflow-hidden h-screen">
      <Header className="z-20 pt-6">
        <HeaderLink />
      </Header>
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <p className="whitespace-no-wrap text-2xl sm:text-5xl lg:text-6xl text-primary-3">Page not found</p>
        <p className="mt-4 text-sm sm:text-lg text-center uppercase font-normal tracking-normal text-primary-1">
          HTTP ERROR 404
          <br />
          rocks are falling
          <br />
          from the sky
        </p>
      </div>
      <AnimStone className="w-16 sm:w-24 text-primary-3 fill-current" style={{ left: '20%', animationDelay: '4s' }} viewBox="0 0 53 159">
        <path d="M34.3831 0.322587L32.0536 0.94732C31.2084 1.18544 30.438 1.6362 29.816 2.2561C29.194 2.876 28.7409 3.64443 28.4999 4.48888C28.4158 4.80156 28.2955 5.10346 28.1415 5.38834L15.3522 27.715C15.1437 28.0862 15.0012 28.4907 14.931 28.9106L9.04548 61.5324C8.80649 62.3367 10.104 66.3094 9.99569 67.1515L6.89417 112.358C6.88139 112.558 6.85343 112.757 6.81053 112.952L0.425622 141.66C0.220402 142.646 0.28838 143.668 0.622242 144.618C0.956104 145.568 1.54315 146.409 2.31996 147.05L13.3072 155.906C13.7696 156.292 14.3414 156.523 14.942 156.567L49.3624 158.872C50.2259 158.861 51.0497 158.508 51.6528 157.89C52.2558 157.272 52.5887 156.44 52.5784 155.576C52.5765 155.402 52.5607 155.228 52.5313 155.056L38.2746 3.47048C38.2699 3.04376 38.1812 2.62213 38.0135 2.22973C37.8457 1.83734 37.6022 1.48172 37.297 1.18348C36.9917 0.88525 36.6307 0.650069 36.2346 0.491472C35.8384 0.332874 35.4148 0.254061 34.9881 0.259284C34.7848 0.261031 34.5822 0.282053 34.3831 0.322587Z" />
      </AnimStone>
      <AnimStone className="w-12 sm:w-20 text-primary-1 fill-current" style={{ left: '30%', animationDelay: '1s' }} viewBox="0 0 40 119">
        <path d="M4.052 5.01089C3.69197 5.25674 3.39871 5.58838 3.19867 5.97573C2.99862 6.36308 2.89806 6.79423 2.906 7.23012L2.44598 35.383C2.4388 35.7669 2.35875 36.1461 2.21002 36.5001L0.230038 41.1798C0.068354 41.5668 -0.00968978 41.9837 0.00103376 42.403L1.19201 109.036C1.19406 109.501 1.30244 109.96 1.50885 110.378C1.71525 110.795 2.01421 111.16 2.38299 111.444L10.929 117.674C11.2129 117.892 11.5485 118.033 11.9031 118.083C12.2577 118.132 12.6192 118.089 12.952 117.957L35.03 108.797C35.3669 108.648 35.6645 108.423 35.8996 108.139C36.1347 107.855 36.3008 107.52 36.385 107.162L39.4 95.7521C39.5638 95.1189 39.5289 94.4507 39.3 93.838L30.843 71.07C30.7901 70.9335 30.748 70.7931 30.717 70.65L23.252 36.9992L23.221 36.8078L13.607 2.92495C13.5067 2.31233 13.1672 1.76479 12.6631 1.40249C12.159 1.04018 11.5316 0.892644 10.919 0.99282C10.6821 1.02997 10.4525 1.10476 10.239 1.21401L4.052 5.01089Z" />
      </AnimStone>
      <AnimStone className="w-6 sm:w-8 text-primary-3 fill-current" style={{ left: '45%', animationDelay: '2.5s' }} viewBox="0 0 19 94">
        <path d="M17.955 58.905L18.379 31.1511C18.3824 30.8696 18.3661 30.588 18.33 30.3088L16.78 18.635C16.7524 18.4408 16.736 18.2452 16.731 18.0491L16.3471 5.03491C16.3011 3.37091 15.657 1.94291 14.82 1.65991L10.828 0.211175C10.6014 0.141092 10.358 0.148974 10.1364 0.233636C9.91489 0.318298 9.72812 0.474647 9.60603 0.677972L5.82405 5.54907C5.75042 5.65585 5.71067 5.78215 5.71004 5.91186L5.24904 37.758C5.23763 37.9708 5.21463 38.1826 5.18007 38.3928L1.80702 63.7761L1.76906 64.1199L0.469071 83.4748C0.310963 84.8029 0.680827 86.1401 1.49904 87.198L5.85603 91.4768C6.02511 91.6596 6.24658 91.786 6.49007 91.8381L17.0681 93.8088C18.1171 94.0018 18.9251 92.4029 18.8681 90.3699L17.939 59.1702L17.955 58.905Z" />
      </AnimStone>
      <AnimStone className="w-6 sm:w-8 text-primary-1 fill-current" style={{ left: '60%', animationDelay: '5.5s' }} viewBox="0 0 19 129">
        <path d="M18.68 122.046L18.542 74.583C18.5371 73.7735 18.4876 72.9646 18.394 72.1606L18.162 45.122C18.1028 38.3907 17.1412 31.6974 15.303 25.2216C12.162 14.1456 7.36099 -0.438164 6.16799 0.463836L4.91 1.39597C3.66349 2.98279 3.09708 4.9991 3.33498 7.0029L1.68801 31.4541C1.68801 32.2681 0.274008 40.5539 0.373008 41.3969L2.36501 62.3618C2.58501 64.7228 2.64302 67.0996 2.69802 69.4726C2.81178 74.3326 3.3958 79.1705 4.44198 83.9179L12.412 120.026C12.498 122.877 13.5812 125.607 15.473 127.741C17.219 129.024 18.683 126.46 18.667 122.041L18.68 122.046Z" />
      </AnimStone>
      <AnimStone className="w-4 sm:w-6 text-primary-1 fill-current" style={{ left: '70%', animationDelay: '0s' }} viewBox="0 0 16 55">
        <path d="M15.096 24.9809C15.1676 24.6415 15.1945 24.2945 15.1759 23.9482L13.9759 3.34808C13.9847 2.58166 13.6889 1.84316 13.1533 1.29485C12.6177 0.746543 11.8864 0.433476 11.12 0.424247C10.9451 0.42323 10.7705 0.436961 10.5979 0.465263C9.88926 0.631577 9.24482 1.0014 8.74405 1.52972C8.24329 2.05803 7.90811 2.72136 7.77994 3.43792L0.974948 36.7822C0.902696 37.1373 0.87951 37.5008 0.905978 37.8622L1.93894 51.4443C1.96232 51.9993 2.13599 52.5373 2.44138 53.0014C2.74677 53.4655 3.17243 53.838 3.67295 54.079L4.94693 54.6401C5.80517 54.9259 6.74186 54.8592 7.55088 54.4545C8.3599 54.0498 8.97502 53.3401 9.26096 52.4819C9.32683 52.2836 9.3747 52.0799 9.40396 51.873L15.096 24.9809Z" />
      </AnimStone>
      <AnimStone className="w-10 sm:w-16 text-primary-3 fill-current" style={{ left: '85%', animationDelay: '3s' }} viewBox="0 0 36 120">
        <path d="M11.797 2.8308L2.54497 17.509C1.19671 19.9675 0.529042 22.7415 0.61095 25.5442L0.0799439 98.2302C0.0399638 101.455 0.682357 104.652 1.96495 107.611L4.94695 114.174C7.00295 118.701 10.199 120.533 12.792 118.666L29.792 106.414C31.751 104.604 32.9279 102.102 33.073 99.4392C33.759 94.3982 34.673 86.8691 35.162 82.2551C35.4181 79.6835 35.2522 77.0872 34.6709 74.5691L28.6819 48.5481C28.5539 47.9834 28.438 47.4062 28.334 46.8162L22.888 11.2419C22.7759 9.19265 22.2094 7.19412 21.2294 5.39086C20.2494 3.5876 18.8805 2.02498 17.222 0.816153L17.115 0.766837C16.1416 0.337458 15.0375 0.31232 14.0454 0.696525C13.0533 1.08073 12.2543 1.84316 11.824 2.81615V2.82787L11.797 2.8308Z" />
      </AnimStone>
    </MainWithMenu>
  </Layout>
)

export default NotFoundPage
