// This is taken directly from the official gatsby-starter-default
// https://github.com/gatsbyjs/gatsby-starter-default
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description = '', lang = 'en', meta = [], type = `website`, pageUrl, cardImage, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  const siteUrl = site.siteMetadata.siteUrl || ''
  const metaPublicationDate = site.buildTime
  const metaAuthor = site.siteMetadata.author || 'Tumba Solutions'
  const metaDescription = description || site.siteMetadata.description
  const metaUrl = siteUrl + (pageUrl || '')
  const metaImage = siteUrl + (cardImage || `/img/tumbacard.png`)
  const titleTemplate = (title === site.siteMetadata.title) ? `%s` : `%s | ${site.siteMetadata.title}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      link={[
        {
          rel: `canonical`,
          key: metaUrl,
          href: metaUrl,
        },
      ]}
      meta={[
        {
          name: `author`,
          property: `article:author`,
          content: metaAuthor,
        },
        {
          name: `publish_date`,
          property: `article:published_time`,
          content: metaPublicationDate,
        },
        {
          name: `title`,
          property: `og:title`,
          content: title,
        },
        {
          name: `description`,
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: `Tumba Solutions`,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          name: `image`,
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:secure_url`,
          content: metaImage,
        },
        {
          property: `og:image:alt`,
          content: title,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:image:alt`,
          content: title,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@TumbaSolutions`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pageUrl: PropTypes.string,
  cardImage: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
